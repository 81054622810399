import { Container, Loader } from "semantic-ui-react";
import { useParams, Navigate } from "react-router-dom";
import { Post as PostController } from "../../../api";
import "./Post.scss";
import { useQuery } from "react-query";

const postController = new PostController();

export function Post() {

  const {path } = useParams();
  
  const { data:post, isLoading }= useQuery(["post" , path],  () => postController.getPost(path) )

  if(post === 400)return(< Navigate to="/blog" />);

  if(isLoading) return(
    <div className="cargando">
      <h4>Loading..</h4>
      <Loader active inline='centered' />
  </div>)
  return (
    <div className="post-page">
         <Container className="post-page__ok">
      <h1 className="title">{post.title}</h1>
      
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: post.content }}
      />
        </Container>
  
    </div>
 
  ); 

  

  }

