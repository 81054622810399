import React, { useState } from "react";
import { Button, Icon, Confirm } from "semantic-ui-react";
import { Contacto } from "../../../../api";
import { useAuth } from "../../../../hooks";
import "./ContactoItem.scss";

const contactoController = new Contacto();

export function ContactoItem(props) {
  const { contacto, onReload } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const { accessToken } = useAuth();

  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);

  const onDelete = async () => {
    try {
      await contactoController.deleteContacto(accessToken, contacto._id);
      onReload();
      onOpenCloseConfirm();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="contacto-item">
        <h4>Nombre: {contacto.nombre}</h4>
        <h4>Correo: {contacto.email}</h4>
        <p><span>Mensaje</span>: {contacto.content}</p>
        <div>
          <Button icon color="red" onClick={onOpenCloseConfirm}>
            <Icon name="trash" />
          </Button>
        </div>
      </div>

      <Confirm
        open={showConfirm}
        onCancel={onOpenCloseConfirm}
        onConfirm={onDelete}
        content={`Eliminar ${contacto.nombre}`}
        size="mini"
      />
    </>
  );
}
