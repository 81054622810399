import { ENV } from "../utils";

export class Home {
     GetHome = async (params=1, limit = 2) => {
        try {
          const pageFilter = `page=${params}`;
          const limitFilter = `limit=${limit}`;
          const url2 = `${ENV.BASE_API}/${ENV.API_ROUTES.PROYECTO}?${pageFilter}&${limitFilter}`;
          const url1 = `${ENV.BASE_API}/${ENV.API_ROUTES.POST}?${pageFilter}&${limitFilter}`;
          const aux1 = await fetch(url1) 
          const aux2 = await fetch(url2);
          const result1 =  await aux1.json();
          const result2 = await aux2.json();
          
          const response = result1.docs.concat(result2.docs);
          if (aux1.status !== 201 &&  aux2.status !== 201) throw response;
          return {docs:response, page:result1.page, pages:result1.pages};
      
        } catch (error) {
            throw error;
        }
      
    
      }
}
