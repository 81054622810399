import React, { useCallback,useEffect,useRef } from "react";
import { Form, Image } from "semantic-ui-react";
import { useDropzone } from "react-dropzone";
import { Editor } from "@tinymce/tinymce-react";
import { useFormik } from "formik";
import BundledEditor from './BundledEditor';
      
/************************************* */
import { useAuth } from "../../../../hooks";
import { ENV } from "../../../../utils";
import { Post } from "../../../../api";
import { initialValues, validationSchema } from "./PostForm.form";
import "./PostForm.scss";

const postController = new Post();

export function PostForm(props) {
  const editorRef = useRef(null);
  const { onClose, onReload, post} = props;
  const { accessToken } = useAuth();

  const formik = useFormik({
    initialValues: initialValues(post),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if(post){
          await postController.updatePost(accessToken, post._id, formValue);
        }else{

          await postController.createPost(accessToken, formValue);
        }
        onReload();
        onClose();
          
      } catch (error) {
        console.error(error);
      }
    },
  });

  const onDrop = useCallback((acceptedFile) => {
    const file =acceptedFile[0];
      formik.setFieldValue("miniature", URL.createObjectURL(file));
      formik.setFieldValue("file", file);
});
      

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpg, image/png",
    onDrop,
  });

  const getMiniature = () => {
    if (formik.values.file) {
      return formik.values.miniature;
    } else if (formik.values.miniature) {
      return `${ENV.BASE_PATH}/${formik.values.miniature}`;
    }
    return null;
  };

  return (
    
    <Form className="post-form" onSubmit={formik.handleSubmit}>
      
      
      <Form.Group widths="equal">
          
        <Form.Input
            name="title"
            placeholder="Titulo del post"
            onChange={formik.handleChange}
            value={formik.values.title}
            error={formik.errors.title}
        />
        <Form.Input
          name="path"
          placeholder="path del post"
          onChange={formik.handleChange}
          value={formik.values.path}
          error={formik.errors.path}
        />
         <Form.Input
          name="pathHome"
          placeholder="pathHome del post"
          onChange={formik.handleChange}
          value={formik.values.pathHome}
          error={formik.errors.pathHome}
        />
      </Form.Group>

      <Form.Group widths="equal">
          
        <Form.Input
            name="fecha_actualizacion"
            placeholder="Fecha de edicion(Opcional)"
            onChange={formik.handleChange}
            value={formik.values.fecha_edicion}
            error={formik.errors.fecha_edicion}
        />
        <Form.Input
            name="autor"
            placeholder="Autor/ra del post"
            onChange={formik.handleChange}
            value={formik.values.autor}
            error={formik.errors.autor}
        />

      </Form.Group>
        
      <BundledEditor
        //apiKey='woby4ct883zk6013gs1xmqmt8cef2p7p5jpmd1z49i20ddi9'
        onInit={(evt, editor) => editorRef.current = editor}

      init={{

        plugins: 'anchor autolink charmap codesample code emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss markdown',
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        file_picker_types: 'file image media',
        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
        codesample_global_prismjs: true,
        content_style: 'body { font-family:Times New Roman,Arial,sans-serif; font-size:19px;} '

      }}
      initialValue={formik.values.content}
      onBlur={(e) => formik.setFieldValue("content", e.target.getContent())}

    />

      {/**imagenes del post  */}  

      <div className="post-form__miniature" {...getRootProps()}>

        <input {...getInputProps()}/>
            {getMiniature() ? ( 
              <Image size="small" src={getMiniature()}/>
            ) : (
              <div className="ima">
                <p>Arrastra tu imagen de miniatura</p>
              </div>
            )}    

      </div>
  
        
    <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
      {post ? "Actualizar post" : "Crear post"}
    </Form.Button>

    </Form>
  
    
  )
}
