import React from "react";
import { Grid, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "./Menu.scss";

export function Menu() {
  return (
    <div className="footer-menu">
      <h4>Lo que podrias encontrar</h4>

      <Grid columns={2}>
        <Grid.Column>
          <p >
            <Icon name="book" /> Automatizacion y Control Industrial
          </p>
          <p>
            <Icon name="code" /> Desarrollo web
          </p>
          <p>
            <Icon name="database" /> Bases de datos
          </p>
        </Grid.Column>

        <Grid.Column>
          <p>
            <Icon name="server" /> Sistemas Embebidos e IoT
          </p>
          <p>
            <Icon name="cogs" /> CMS
          </p>
          <p>
            <Icon name="user outline" /> Salud Mental
          </p>
          <p>
            <Icon name="python" /> Backend
          </p>
        </Grid.Column>
      </Grid>
    </div>
  );
}
