import { ENV } from "../utils";

export class Proyecto {
  baseApi = ENV.BASE_API;

  async createProyecto(accessToken, data) {
    try {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      if (data.file) {
        formData.append("miniature", data.file);
      }

      const url = `${this.baseApi}/${ENV.API_ROUTES.PROYECTO}`;
      const params = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 201) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  getProyectos = async (params=1, limit = 2) => {
    try {
      const pageFilter = `page=${params}`;
      const limitFilter = `limit=${limit}`;
      const url = `${ENV.BASE_API}/${ENV.API_ROUTES.PROYECTO}?${pageFilter}&${limitFilter}`;
      const aux = await fetch(url) 
      const response =  await aux.json();   
      if (aux.status !== 201) throw response;
      return response;
  
    } catch (error) {
        throw error;
    }
  

  }

  async updateProyecto(accessToken, idProyecto, data) {
    try {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      if (data.file) {
        formData.append("miniature", data.file);
      }

      const url = `${this.baseApi}/${ENV.API_ROUTES.PROYECTO}/${idProyecto}`;
      const params = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async deleteProyecto(accessToken, idProyecto) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.PROYECTO}/${idProyecto}`;
      const params = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 201) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }
  async getProyecto(path) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.PROYECTO}/${path}`;

      const response = await fetch(url);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      return(error);
    }
  }
}
