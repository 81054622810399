import { Button, Loader } from "semantic-ui-react";
import { map } from "lodash";
import { Proyecto } from "../../../../api";
import "./ListProyectos.scss";
import {ListProyectoItem} from "../ListProyectoItem";
import { useInfiniteQuery } from "react-query";



const proyectoController = new Proyecto();

export function ListProyectos() {



  const { data, error1, isLoading , hasNextPage, fetchNextPage }= useInfiniteQuery(["mis-proyectos" ],  ({pageParam}) => proyectoController.getProyectos(pageParam,2)
  ,{getNextPageParam: (lasPage) => {
    if(lasPage.page === lasPage.pages) return false;
    //  console.log(lasPage.page);

    return lasPage.page +1
  }
    
  }) 
  const proyectos= data?.pages?.flatMap(paginas =>paginas.docs) ?? []
  return (
    <div className="list-proyectos">

      <div className="list">
          {map(proyectos, (proyecto) => (
            <div key={proyecto._id} className="item">
            <ListProyectoItem proyecto = {proyecto} />
            </div>
          ))}
      </div>
      

      {hasNextPage && (
        <div className="more">
          <Button primary onClick={()=>{void fetchNextPage()}}>
            Cargar más...
          </Button>
        </div>
      )}

      {(isLoading) && 
      
      <div className="loading">
        <h4>Loading...</h4>
        <Loader active inline='centered' />
      </div>}

  </div>
  );
}