import React, {useState } from 'react'
import { Tab, Image} from "semantic-ui-react"
import "./Auth.scss";

import { RegisterForm, LoginForm } from "../../../components/Admin/Auth";

const logo = require.context("../../../assets/Logo", true);

export function Auth() {
  const [activeIndex, setActiveIndex] = useState(0);

  const openLogin = () => setActiveIndex(0);

  const panes =[
    {
      menuItem: "Entrar",
      render: () =>(
        <Tab.Pane >
          <LoginForm />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Nuevo Usuario",
      render: () =>(
        <Tab.Pane >
          <RegisterForm openLogin={openLogin} />
        </Tab.Pane>
      ),
    },
  ];

  return (

    
  
      <div className="auth">
        <Image 
          src={logo(`./logotipo_1.png`)}
          alt="Logo"
          className="logo"
        />
         
        <Tab panes={panes} className="auth__forms" activeIndex={activeIndex} onTabChange={(_, data) => setActiveIndex(data.activeIndex)}/>
      </div>

      

    
    
  );
}


