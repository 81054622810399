import React from "react";
import { Container } from "semantic-ui-react";
import { ListPosts } from "../../../components/Web/Blog";
import "./Blog.scss";

export function Blog() {
  return (
    <div className="blog-size1">
      <Container className="blog-size">
      <div className="description">  
          <h2>
            Blogs
          </h2>
      </div>
      <ListPosts />
    </Container>
    </div>
  );
}
