import React from 'react'
import {ContactForm} from "../../../components/Web/Contact";

import "./Contact.scss"

export function Contact() {
  return (
    <div className='contact1'>
      <ContactForm />      
        
    </div>
  )
}
