
import { Container } from "semantic-ui-react";
import {ListProyectos} from "../../../components/Web/Proyectos"

import "./Proyectos.scss";


export function Proyectos() {
 

  return (
    <div className="proyectos-page">
      <Container className="proyectos-page__ok">
      <div className="description">  
          <h2>
            Proyectos
          </h2>
      </div>
      
      <ListProyectos />
      
    </Container>
    </div>
    
  );
}