import React from "react";
import { Image  } from "semantic-ui-react";
import { Link} from "react-router-dom";
import { DateTime } from "luxon";
import { ENV } from "../../../../utils";
import "./ListProyectoItem.scss";

export function ListProyectoItem(props) {
  const { proyecto } = props;
  const date = new Date(proyecto.created_at);

  return (
    
  
    <div className="home-proyectos">
                  
        <div className="thumb">
          <div  className="contenedor">
                       
          <Link to={`${proyecto.path}`}>
            <div className="diseño">
                         
              <Image src={`${ENV.BASE_PATH}/${proyecto.miniature}`} />
              <div className="capa">
              </div>
            </div>
          </Link>   
                       
          <div className="info">
            <h2 className="titulo">{proyecto.title}</h2>
              <p className="fecha">
                {DateTime.fromISO(date.toISOString())
                .setLocale("col")
                .toFormat("dd 'de' LLLL 'del' yyyy")}
              </p>

              < div
                className="description"
                dangerouslySetInnerHTML={{ __html: proyecto.content }}
              
              />
                <div className="boton">
      
                 <Link className="link-color" to={`${proyecto.path}`}>Seguir leyendo..</Link>
                </div>                 
          </div>
                           
          </div>
                     
        </div> 
                       
                     
      
    </div>
      
  );
}