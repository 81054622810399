import React, { useState } from "react";
import "./TopBar.scss";
import {Button, Menu as meniu, Icon} from "semantic-ui-react";
import {Link, useLocation} from "react-router-dom";
import {Menu as Meniu} from "../../../api"
import {map} from "lodash";
import {socialData} from "../../../utils"
import { BurguerButton } from "./BurguerButton";
import {useQuery} from "react-query"
const logo = require.context("../../../assets/Logo");

const menuController = new Meniu();
 
export function TopBar() {
  const { pathname } = useLocation();

  const isCurrentPath = (path) =>{

    if(path===pathname) return true;
    return false;
}


  const { data:menu, isLoading }= useQuery(["menus"],  () => menuController.getMenu(true) )


  const [clicked, setClicked] = useState(false)
  const handleClick = () => {
    //cuando esta true lo pasa a false y vice versa
    setClicked(!clicked)
  }


  return (
    <>
      
        <div className={`edit ${clicked ? 'active' : ''}`}>

          <Link to="/" className="log">
          <img 
            src={logo(`./logotipo.png`)}
            alt="Logo"
            className="logo"
          />
          </Link>

          <div className={`links ${clicked ? 'active' : ''}`}  >
          
            <div className= "active1">
            {map(menu, (item)=>(
              <meniu.Item  as={Link} to={item.path} active={isCurrentPath(item.path)} >
                 <Icon name={item.title} />
                 {item.title ==='home'? <div className="edit"><h1>Inicio</h1></div>: null}
                 {item.title ==='comments'? <div className="edit"><h1>Blogs</h1></div>: null}
                 {item.title ==='chess rock'? <div className="edit"><h1>Proyectos</h1></div>: null}
                 {item.title ==='address book outline'? <div className="edit"><h1>Contacto</h1></div>: null}

              </meniu.Item>

            ))}
            </div>
          </div>
              
          <div className={`burguer ${clicked ? 'active' : ''}`}>
            
            <div className="linea">
              <BurguerButton clicked={clicked} handleClick={handleClick} />
            </div>
          </div>
        

          <div className={`social ${clicked ? 'active' : ''}`}>
            {map(socialData, (social)=>(

              <Button 
                className="boton-social"
                key={social.type}
                as="a"
                target="_blank"
                href={social.link}
                color={social.type}
                icon={social.type}

              />
            ))}
          </div>

        </div>
    
    </>
  );
}



