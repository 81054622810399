import { Image, Button, Loader} from "semantic-ui-react";
// eslint-disable-next-line no-unused-vars
import { Link} from "react-router-dom";
import { DateTime } from "luxon";
import { map, size} from "lodash";
import { Home} from "../../../api";
import { ENV } from "../../../utils";
import "./HomeProyectos.scss";
import { useInfiniteQuery } from "react-query";

const homeController = new Home()

export function HomeProyectos() {




  const { data:misdatos, isLoading , hasNextPage, fetchNextPage }= useInfiniteQuery(["mis-datos"],  ({pageParam}) => homeController.GetHome(pageParam,2)
  ,{getNextPageParam: (lasPage) => {
    if(lasPage.page === lasPage.pages) return false;
    return lasPage.page +1
  }
    
  }) 
  
  const datosH= misdatos?.pages?.flatMap(paginas =>paginas.docs) ?? []
  return (
          
    <div className="home1__all">
          <div className="titulo-linea">
            <div className="titulo">
              <h3 className="titulo1">Lo más reciente</h3>
          </div>
          </div>
          <div className="linea"></div>
    
    <div className="list1">

        <div 
          
            className={`${size(datosH)? ('public') :('public1')}`}
            
            ><h1>Publicidad1  Pagada</h1>
        </div>
          {size(datosH) > 0 && 
            <>  
              <div className="list">
              
                  {map(datosH, (dato=> (                   
                    <div  key={dato._id} className="thumb1">  
                        <div className="container">

                          <Link to={`${dato.pathHome}`}>
                            <div className="diseño">
                              <Image src={`${ENV.BASE_PATH}/${dato.miniature}`} width="500" />
                              <div className="capa">
                              </div>
                            </div>
                          </Link>
                         
                          <div className="info">
                            <h2 className="titulo">{dato.title}</h2>
                            <p class="fecha">
                            {DateTime.fromISO((new Date(dato.created_at)).toISOString())
                                .setLocale("es")
                                .toFormat("dd 'de' LLLL 'del' yyyy")}
                                </p>
                             
                             <div className="description"
                                dangerouslySetInnerHTML= {{ __html: dato.content }}

                              > 
                              </div>
                              <div className="boton">
                                <a href={`${dato.pathHome}`}>Seguir leyendo..</a>
                                </div>
                          </div>
                                    
                        </div>
                        </div>
                            
                  )))}  
                </div>  
            </> 
          }
          <div 
            className={`${size(datosH)? ('public2') : ('public3')}`}
          
            ><h1>Publicidad2 Politica Pagada</h1>
          </div> 

          { hasNextPage && (
                  <div className="more1">
                    <Button primary onClick={()=>{void fetchNextPage()}}>
                      Cargar mas...
                    </Button>
                  </div>
                  )
          }
          
        {(isLoading) && 
      
          <div className="loading">
            <h4>Loading...</h4>
            <Loader active inline='centered' />
          </div>
        }
      
    </div>
  </div>  
       
  );
}
