import React from "react";
import { Button } from "semantic-ui-react";
import { map } from "lodash";
//import {image } from "../../../../assets";
import { socialData } from "../../../../utils";
import "./Info.scss";

const logo = require.context("../../../../assets/Logo");

export function Info() {
  return (
    <div className="footer-info">
      <img 
            src={logo(`./logotipo_1.png`)}
            alt="Logo"
            className="logo"
          />
      <p>
        {map(socialData, (social) => (
          <Button
            key={social.type}
            as="a"
            target="_blank"
            href={social.link}
            color={social.type}
            icon={social.type}
          />
        ))}
      </p>

    
    </div>
  );
}
