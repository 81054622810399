import React, { useLayoutEffect} from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import {Home,Proyectos,Proyecto, Contact, Blog,Post} from "../pages/web";
import {Auth, Users, BlogAdmin, ProyectosAdmin, MenuAdmin,Newsletter, ContactoAdmin} from "../pages/admin";
import {ClientLayout} from "../layouts/ClientLayout";
import { AdminLayout } from "../layouts";
import {useAuth} from "../hooks";

import {ErrorPageAdmin} from "./ErrorPageAdmin";


import "./WebRouter.scss";


export function Router() {

  //compomente para solucionar el problema de la posicion de los Links al hacer un redireccionamiento.
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

const { user } = useAuth();


  const loadLayouterror = (Layout, Page) =>{
    return( 
      <Layout>
        <Page />
        <Navigate to="/"/>
      </Layout>
    )

  }

  const loadLayout = (Layout, Page) =>{
    return( 
      <Layout>
        <Page />
      </Layout>
    )

  }

  return(
    <Wrapper>
      <React.StrictMode>
      <Routes>
          
            <Route path="/" element={loadLayout(ClientLayout,Home)} />
            <Route path="/proyecto" element={loadLayout(ClientLayout, Proyectos)} />
            <Route exact path="/proyecto/:path" element={loadLayout(ClientLayout, Proyecto)} />
            <Route path="/contact" element={loadLayout(ClientLayout, Contact)} />
            <Route path="/blog" element={loadLayout(ClientLayout, Blog)} />
            <Route path="/blog/:path" element={loadLayout(ClientLayout, Post)} />
            <Route path= "*" element={loadLayouterror(ClientLayout, Home)} />
        
            {!user ? (

              <Route path="/admin" element={<Auth/>}/>

            ) : (

              <>
                {["/admin", "/admin/blog"].map((path) =>(

                    <Route
                        key={path}
                        path={path} 
                        element={loadLayout(AdminLayout, BlogAdmin)} />
                ))}

                <Route path="admin/users" element={loadLayout(AdminLayout, Users)} />
                <Route path="/admin/proyecto" element={loadLayout(AdminLayout, ProyectosAdmin)} />
                <Route path="/admin/menu" element={loadLayout(AdminLayout, MenuAdmin)} />
                <Route path="/admin/newsletter" element={loadLayout(AdminLayout, Newsletter)} />
                <Route path="/admin/contact" element={loadLayout(AdminLayout, ContactoAdmin)} />
                <Route path= "/admin/*" element={loadLayout(AdminLayout,ErrorPageAdmin)} />

              </>


            )};
      
      </Routes>
      </React.StrictMode>
  </Wrapper>

  );
}