import React from 'react';
import {Menu, Icon} from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import {useAuth} from "../../../../hooks"
import "./AdminMenu.scss";

export function AdminMenu() {

    const { pathname } = useLocation();

    const {
        user: {role},
    } = useAuth();

    const isAdmin = role === "admin";

    const isCurrentPath = (path) =>{

        if(path===pathname) return true;
        return false;
    }

  return (
   
    <Menu fluid vertical icon text className='admin-menu'>
          {/*Creamos los item del menu con la etiqueta Menu.Item*/}

        {/** usamos la propiedad del Link de  react-router-don para que nuestro icono
         nos redirija a la sig direccion cuando lo presionemos, para eso escribimos 
         la sig instruccion en la etiqueta Menu.Item: as={Link} to="/admin/users" 
        */}
        { isAdmin   ?(
            <>
                <Menu.Item as={Link} to="/admin/users" active={isCurrentPath("/admin/users")}>
                    {/*ahora creamos un icono con Icon*/}
                    <Icon name='user outline' />
                    Usuario
                </Menu.Item>
                {/**Generamos los demas menus copiando y pegando el priero */}

                {/**Cambiamos las rutas a las que nos redirije el icono cuando le damos click,
             * colocamos /admin/menu, colocamos bars en name*/}
             
                <Menu.Item as={Link} to="/admin/menu" active = {isCurrentPath("/admin/menu")}>
                    {/*ahora creamos un icono con Icon*/}
                    <Icon name='bars' />
                    Menu
                </Menu.Item>
                {/**Generamos el icono de cursos copiando y pegando el anterior */}
            
                {/**Cambiamos las rutas a las que nos redirije el icono cuando le damos click,
                * colocamos /admin/proyectos, colocamos computer en name
                */}
                <Menu.Item as={Link} to="/admin/proyecto" active = {isCurrentPath("/admin/proyecto")} >
                    {/*ahora creamos un icono con Icon*/}
                    <Icon name='chess rook' />
                    Proyectos
                </Menu.Item>

                <Menu.Item as={Link} to="/admin/blog" active={isCurrentPath("/admin/blog")}>
                    {/*ahora creamos un icono con Icon*/}
                    <Icon name='comment alternate' />
                    Blog
                </Menu.Item>

                <Menu.Item as={Link} to="/admin/newsletter" active={isCurrentPath("/admin/newsletter")}>
                    {/*ahora creamos un icono con Icon*/}
                    <Icon name='mail' />
                    Newsletter
                </Menu.Item>
                <Menu.Item as={Link} to="/admin/contact" active={isCurrentPath("/admin/contact")}>
                    {/*ahora creamos un icono con Icon*/}
                    <Icon name='address book'/>
                    Contactos
                </Menu.Item>
             </> 
                
            ) :(
                <Menu.Item as={Link} to="/admin/blog" active={isCurrentPath("/admin/blog")}>
                    {/*ahora creamos un icono con Icon*/}
                    <Icon name='comment alternate' />
                    Blog
                </Menu.Item> 
            )};       
        </Menu>
  )
};
