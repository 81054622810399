import React, { useState, useEffect } from "react";
import { Loader, Pagination } from "semantic-ui-react";
import { map, size } from "lodash";
import { Proyecto } from "../../../../api";
import { ProyectoItem } from "../ProyectoItem";
import "./ListProyectos.scss";

const proyectoController = new Proyecto();

export function ListProyectos(props) {
  const { reload, onReload } = props;
  const [proyectos, setProyectos] = useState(null);
  const [pagination, setPagination] = useState();
  const [page, setPage] = useState(1);

  //para hacer la peticion
  useEffect(() => {
    (async () => {
      try {
        const response = await proyectoController.getProyectos(page);
        
        setProyectos(response.docs);

        setPagination({
          limit: response.limit,
          page: response.page,
          pages: response.pages,
          total: response.total,
        });
      } catch (error) {
        console.error(error);
      }
    })();
  }, [page, reload]);

  const changePage = (_, data) => {
    setPage(data.activePage);
  };

  if (!proyectos) return <Loader active inline="centered" />;
  if (size(proyectos) === 0) return "No hay ningun proyecto";

  return (
    <div className="list-proyecto">
      {map(proyectos, (proyecto) => (
        <ProyectoItem key={proyecto._id} proyecto={proyecto} onReload={onReload} />
      ))}

      <div className="list-proyecto__pagination">
        <Pagination
          totalPages={pagination.pages}
          defaultActivePage={pagination.page}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          onPageChange={changePage}
        />
      </div>
    </div>
  );
}