import React from "react";
import { Container } from "semantic-ui-react";
import {TopBar,Footer} from "../../components/Web"
import "./ClientLayout.scss";

export function ClientLayout(props) {
  const { children } = props;

  return (
    <div className="client-layout">
      <div className="client-layout__header">
      <TopBar />
      </div>

       {children}

      <div className="client-layout__footer">
        <div className="fijo">
          <h2>publicidad fija</h2> 
        </div> 
        <Container>
          <Footer.Info />
          <Footer.Menu />
          <Footer.Newsletter />
         
        </Container>
      
        <div className="fijo1">
        <span>© ALL RIGHTS RESERVED</span>
        <br></br>
          <span className="nombre">Alejandro Urieles | Ingeniero Electrónico & Desarrollador Web</span>
          </div>
        
      </div>
    </div>
  );
}
