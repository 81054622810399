import * as Yup from "yup";

export function initialValues(post) {
  return {
    title: post?.title || "",
    miniature: post?.miniature || "",
    content: post?.content || "",
    path: post?.path || "",
    pathHome: post?.pathHome || "",
    fecha_edicion: post?.fecha_edicion || "", 
    autor: post?.autor || "",
    file: null,
  };
}

export function validationSchema() {
  return Yup.object({
    title: Yup.string().required(true),
    miniature: Yup.string().required(true),
    content: Yup.string().required(true),
    path: Yup.string().required(true),
    pathHome: Yup.string().required(true),
    autor: Yup.string().required(true),
  });
}
