import * as Yup from "yup";

export function initialValues(proyecto) {
  return {
    title: proyecto?.title || "",
    miniature: proyecto?.miniature || "",
    file: null,
    content: proyecto?.content || "",
    path: proyecto?.path || "",
    pathHome: proyecto?.pathHome || "",
    autor: proyecto?.autor || "",
  };
}
//Validacion del formulario de proyecto
export function validationSchema() {
  return Yup.object({
    title: Yup.string().required(true),
    miniature: Yup.string().required(true),
    content: Yup.string().required(true),
    path: Yup.string().required(true),
    pathHome: Yup.string().required(true),
    autor: Yup.string().required(true),
  });
}
