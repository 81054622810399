import React, { useState } from "react";
import { Button, Icon, Confirm } from "semantic-ui-react";
import { BasicModal } from "../../../Shared";
import { Menu } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { MenuForm } from "../MenuForm";
import "./MenuItem.scss";

const menuController = new Menu();

export function MenuItem(props) {
  const { menu, onReload } = props;
  const { accessToken } = useAuth();

  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");

  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [isDelete, setIsDelete] = useState(false);

  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);

  const openUpdateMenu = () => {
    setTitleModal(`Actualizar menu: ${menu.title}`);
    onOpenCloseModal();
  };

  const openDesactivateActiveConfirm = () => {
    setIsDelete(false);
    setConfirmMessage(
      menu.active
        ? `Desactivar el menu ${menu.title}`
        : `Activar el menu ${menu.title}`
    );
    onOpenCloseConfirm();
  };

  const onActivateDesactivate = async () => {
    try {
      await menuController.updateMenu(accessToken, menu._id, {
        active: !menu.active,
      });
      onReload();
      onOpenCloseConfirm();
    } catch (error) {
      console.error(error);
    }
  };

  const openDeleteConfirm = () => {
    setIsDelete(true);
    setConfirmMessage(`Eliminar el menu ${menu.title}`);
    onOpenCloseConfirm();
  };

  const onDelete = async () => {
    try {
      await menuController.deleteMenu(accessToken, menu._id);
      onReload();
      onOpenCloseConfirm();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="menu-item">
        <div className="menu-item__info">
          <span className="menu-item__info-title">{menu.title}</span>
          <span className="menu-item__info-path">{menu.path}</span>
        </div>
          {/**onClick={openUpdateMenu} */}
        <div className="botones">
          <Button animated onClick={openUpdateMenu} >
            <Button.Content visible>
            <Icon name='pencil alternative'/>
            </Button.Content>
            <Button.Content hidden color="blue">
            Edit
            </Button.Content>
          </Button>

          <Button animated="fade" color={menu.active ?  "blue" : "grey"}
              onClick={openDesactivateActiveConfirm} >
            <Button.Content visible color="blue">
              <Icon name={menu.active ? "check" : "ban"} />
            </Button.Content>
            <Button.Content hidden>
              <Icon name={menu.active ? "thumbs down" : "check"} />
            </Button.Content>
          </Button>
          <Button animated="vertical" onClick={openDeleteConfirm}>
            <Button.Content visible>
            <Icon name='trash'/>
            </Button.Content>
            <Button.Content hidden color="blue">
            remuve
            </Button.Content>
          </Button>
            
         
        </div>
      </div>

      <BasicModal show={showModal} close={onOpenCloseModal} title={titleModal}>
        <MenuForm onClose={onOpenCloseModal} onReload={onReload} menu={menu} />
      </BasicModal>

      <Confirm
        open={showConfirm}
        onCancel={onOpenCloseConfirm}
        onConfirm={isDelete ? onDelete : onActivateDesactivate}
        content={confirmMessage}
        size="mini"
      />
    </>
  );
}