import React from "react";
import {
  Banner, HomeProyectos,HowMyProyectosWork
} from "../../../components/Web";
import "./Home.scss";

export function Home() {
  return (
    <div className="border-home">
      <Banner />
      <HomeProyectos />
     
      <HowMyProyectosWork />
    </div>
  );
}

