import { Container, Image } from "semantic-ui-react";
import{image} from "../assets"
import "./ErrorPage.scss";
import { Link } from "react-router-dom";

export function ErrorPageAdmin(){

    return(
        <div className="error-page">
             <Container className="error-page__ok">
             <div class="logo"> 
                <Link to="/admin" className="boton-logo">
                        
                    <Image 
                    src={image.errorimg}
                    className="logo-img"
                  />
                    
                </Link>
            </div>

             <h1 class="error">Contenido no disponible</h1>
                <p>
                    La pagina que intenta acceder no se encuentra disponible.
                </p>
                <Link to="/admin" className="boton"> Volver a la pagina Principal</Link>
                <Link to="/contact" className="boton-reporte"> Reportar un Problema</Link>
            </Container>
        </div>
       

    );


}