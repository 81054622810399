import React from 'react';
import {Menu, Image} from "semantic-ui-react";
import { Link} from "react-router-dom";
import {AdminMenu, Logout} from "../../components/Admin/AdminLayout";
import "./AdminLayout.scss";

const logo = require.context("../../assets/Logo", true);
export function AdminLayout(props) {
    const {children} = props;

    return (
        <div className='admin-layout'>
            <div className='admin-layout__left'>

                {/* imagen del logo */}
            <Menu.Item as={Link} to="/admin" >
                <div className='li'>
                    <Image
                        src={logo(`./logotipo_1.png`)}
                        alt="Logo"
                        className="logo"
                    />
                </div>
                
            </Menu.Item>

                
                
            <AdminMenu />

            </div>
            <div className='admin-layout__right'>
               
                <div className='admin-layout__right-header'>
                   <Logout />

                </div> 
                <div className='admin-layout__right-content'>
                    {children}
                </div>

            </div>

        </div>
  )
}
