import React, { useState } from "react";
import { Container, Form } from "semantic-ui-react";
import { useFormik } from "formik";
import { Contacto as ContactoController } from "../../../api";
import { initialValues, validationSchema } from "./ContactForm.form";
import "./Contact.scss";

const contactoController = new ContactoController();

export function ContactForm() {

  const [successs, setSuccesss] = useState(false);

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      setSuccesss(false);

      try {
        await contactoController.createContacto(formValue);
        formik.resetForm();
        setSuccesss(true);
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <Container>
    <div className="contenedor1">
      <div> 
        <div>
          <p>Para ponerte en contacto con nuestro equipo déjanos un mensaje.</p>
        </div>
      </div> 
      <Form onSubmit={formik.handleSubmit}>
        <Form.Input
          name="nombre"
          placeholder="Escriba su nombre"
          onChange={formik.handleChange}
          value={formik.values.nombre}
          error={formik.errors.nombre}
        />
        <Form.Input
          name="email"
          placeholder="Escriba su Email"
          onChange={formik.handleChange}
          value={formik.values.email}
          error={formik.errors.email}
        />
        <Form.TextArea
          name="content"
          placeholder="Puede dejar información acerca de su solicitud o indicando el nombre de la persona con quien se quiere comunicar"
          onChange={formik.handleChange}
          value={formik.values.content}
          error={formik.errors.content}
        />
        <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
          Enviar
        </Form.Button>

        {successs && (
          <>
            <p className="success">¡Información enviada correctamente, muy pronto tramitaremos su solicitud!</p>
          </>
        )}
      </Form>
     
    </div>
    </Container>
  );
}



