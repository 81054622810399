export const socialData = [
  {
    type: "facebook",
    link: "https://www.facebook.com/alejandro.urielessalcedo",
  },

  {
    type: "twitter",
    link: "https://twitter.com/Alejoseurs",
  },
  {
    type: "instagram",
    link: "https://www.instagram.com/alejoseur/",
  },

  {
    type: "linkedin",
    link: "https://www.linkedin.com/in/alejandro-jos%C3%A9-urieles-salcedo-ba1bb21b5/",
  },
];
