import React from "react";
import { Container, Loader } from "semantic-ui-react";
import { Navigate, useParams } from "react-router-dom";
import { Proyecto as ProyectoController } from "../../../api";
import "./Proyecto.scss";
import {useQuery} from "react-query"

const proyectoController = new ProyectoController();

export function Proyecto() {

  const {path } = useParams();

  const { data:proyecto, isLoading }= useQuery(["proyecto" , path],  () => proyectoController.getProyecto(path) )

//rediccionamiento para solucionar problema de las rutas erroneas

  if(proyecto === 400)return(< Navigate to="/blog" />);

  if(isLoading) return(
    <div className="cargando">
      <h4>Cargando..</h4>
      <Loader active inline='centered' />
  </div>)

  return (
    
    <div className="proyecto-page1">
      <Container className="proyecto-page1__ok">
      <h1 className="title">{proyecto.title}</h1> 
     
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: proyecto.content }}
      />
    </Container>
    </div>
    
  );
}