import React from "react";
import { Container } from "semantic-ui-react";
import "./Banner.scss";

export function Banner() {
  return (
    <div className="banner">
      <Container>
      
      </Container>

      <div className="banner__dark" />
    </div>
  );
}
