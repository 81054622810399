//import { ReactComponent as LogoWhite } from "./svg/tincode-white.svg";
import authBg from "./jpg/auth-bg.jpg";
import noAvatar from "./png/noAvatar.png";
import errorimg from "./png/error.png";





const image = {
  authBg,
  noAvatar,
  errorimg
};

export {  image };
