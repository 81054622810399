export const itemsData = [
  {
    icon: "heart outline",
    title: "",
    description:
      "Contenido diverso",
  },
  {
    icon: "comment alternate outline",
    title: "",
    description:
      "Publicaciones colaboraticovas",
  },
  {
    icon: "user circle outline",
    title: "",
    description:
      "Dejanos tus opiniones en la caja de contactos",
  },
];
