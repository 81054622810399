import * as Yup from "yup";

export function initialValues() {
  return {
    nombre: "",
    email: "",
    content: "",
  };
}

export function validationSchema() {
  return Yup.object({
    nombre: Yup.string().required(true),
    email: Yup.string().email(true).required(true),
    content: Yup.string().required(true),
  });
}
