import React from "react";
import { Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import { ENV } from "../../../../utils";
import "./ListPostItem.scss";

export function ListPostItem(props) {
  
  const { post } = props;
  const date = new Date(post.created_at);

  return (
    <div className="home-blog">
              
        <div className="thumb">
          <div  className="contenedor">
                       
          <Link to={`${post.path}`}>
            <div className="diseño">
                         
              <Image src={`${ENV.BASE_PATH}/${post.miniature}`} />
              <div className="capa">
              </div>
            </div>
          </Link> 

          <div className="info">
            <h2 className="titulo">{post.title}</h2>
              <p class="fecha">
                {DateTime.fromISO(date.toISOString())
                .setLocale("es")
                .toFormat("dd 'de' LLLL 'del' yyyy")}
              </p>
            <div
                className="description"
                dangerouslySetInnerHTML={{ __html: post.content }}
            />
              <div className="boton">
              <Link className="link-color" to={`${post.path}`}>Seguir leyendo..</Link>
              </div>                 
          </div>
                  
          </div>
                     
        </div> 

                
    </div>
  );
}
