import { Button ,  Loader } from "semantic-ui-react";
import { map } from "lodash";
import { Post } from "../../../../api";
import { ListPostItem } from "../ListPostItem";
import { useInfiniteQuery } from "react-query";


import "./ListPosts.scss";

const postController = new Post();

export function ListPosts() {
  
  const { data, isLoading , hasNextPage, fetchNextPage }= useInfiniteQuery(["mis-posts" ],  ({pageParam}) => postController.getPosts(pageParam,2)
  ,{getNextPageParam: (lasPage) => {
    if(lasPage.page === lasPage.pages) return false;
    return lasPage.page +1
  }
    
  }) 
  const posts = data?.pages?.flatMap(paginas =>paginas.docs) ?? []


  return (
    <div className="list-posts-web">
      <div className="list">
        {map(posts, (post) => (
          <div key={post._id} className="item"> 
            <ListPostItem post={post} />
          </div>
        ))}
      </div>
            
      {hasNextPage && (
        <div className="more">
          <Button primary onClick={()=>{void fetchNextPage()}}>
            Cargar más...
          </Button>
        </div>
      )}

      {(isLoading) && 
        <div className="loading">
          <h4>Loading...</h4>
          <Loader active inline='centered' />
        </div>}
    </div>
  );
}
  