import React, { useState } from "react";
import {  Image,Button, Icon, Confirm } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Proyecto } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { BasicModal } from "../../../Shared";
import { ProyectoForm} from "../ProyectoForm";
import { ENV } from "../../../../utils";
import "./ProyectoItem.scss";

const proyectoController = new Proyecto();

export function ProyectoItem(props) {
  const { proyecto, onReload } = props;
  const [showModal, setShowModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const { accessToken } = useAuth();

  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);

  const onDelete = async () => {
    try {
      await proyectoController.deleteProyecto(accessToken, proyecto._id);
      onReload();
      onOpenCloseConfirm();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="proyecto-item">
        <div className="proyecto-item__info">
        <Image src={`${ENV.BASE_PATH}/${proyecto.miniature}`} />
          <span className="proyecto-item__info-title">{proyecto.title}</span>
          <span className="proyecto-item__info-path">{proyecto.path}</span>
        </div>

        <div className="botones">
          <Button as={Link} icon to={`/proyecto/${proyecto.path}`} target="_blank">
            <Icon name="eye" />
          </Button>
          <Button icon primary onClick={onOpenCloseModal}>
            <Icon name="pencil" />
          </Button>
          <Button icon color="red" onClick={onOpenCloseConfirm}>
            <Icon name="trash" />
          </Button>
        </div>
      </div>

      <BasicModal
        show={showModal}
        close={onOpenCloseModal}
        title="Editar proyecto"
        size="large"
      >
        <ProyectoForm onClose={onOpenCloseModal} onReload={onReload} proyecto={proyecto} />
      </BasicModal>

      <Confirm
        open={showConfirm}
        onCancel={onOpenCloseConfirm}
        onConfirm={onDelete}
        content={`¿Eliminar ${proyecto.title}?`}
        size="mini"
      />
    </>
  );
}